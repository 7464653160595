<template>
  <div :style="getStyle" v-if="data.properties.filed_content != 'Hide'">
    <el-row align="middle">
      <span
        v-if="
          !data.properties.hideLabel || data.properties.filed_content != 'Hide'
        "
      >
        <label
          for="text"
          v-if="hasLabel"
          :class="{ 'highlight-border': highlight }"
        >
          {{ label || data.label }}
        </label>
        <span
          v-if="data.validations.required && hasLabel"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </span>

      <el-col>
        <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
      </el-col>

      <!-- <YouTubePlayer></YouTubePlayer> -->

      <span v-if="data.filled_by === 'RECEIVER' || data.filled_by === 'SENDER'">
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <el-input
            v-if="
              !isView &&
              data.properties.filed_content != 'Hide' &&
              data.showLink
            "
            placeholder="Enter Video Link"
            v-model="form[data.key]"
          />
          <div id="iframe" v-if="iframeURL" v-loading="loading">
            <iframe
              :style="getStyle()"
              :src="iframeURL"
              frameborder="5"
              allowfullscreen
            ></iframe>
          </div>

          <div v-else>
            <img
              alt="Avatar"
              src="../../../../src/assets/img/thumbnail.jpg"
              :height="this.data.height - 30"
              :width="this.data.width"
              class="inline-block"
            />
          </div>
          <input
            type="file"
            accept="video/*"
            ref="fileInput"
            style="display: none"
            @change="handleVideoFileUpload($event.target.files[0])"
          />
          <el-button
            v-if="
              !isView &&
              data.properties.filed_content !== 'Hide' &&
              data.filled_by === 'RECEIVER'
            "
            class="upload-file upload-button"
            @click="enableDialog"
            :loading="loading"
          >
            Upload
          </el-button>
          <el-button
            v-if="
              !isView &&
              data.properties.filed_content !== 'Hide' &&
              data.filled_by === 'RECEIVER' &&
              iframeURL
            "
            style="background-color: ivory; max-width: 27px; margin-left: 5px"
            type="plain"
            size="mini"
            circle
            @click="deleteImage()"
          >
            <img width="12px" src="@/assets/Trash.svg" />
          </el-button>
        </el-col>
      </span>
      <span v-else>
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <div id="iframe" v-if="iframeURL">
            <iframe
              :style="getStyle()"
              :src="iframeURL"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
          <div v-else>
            <img
              alt="Avatar"
              src="../../../../src/assets/img/thumbnail.jpg"
              :height="this.data.height - 30"
              :width="this.data.width"
              class="inline-block"
            />
          </div>

          <input
            type="file"
            accept="video/*"
            ref="fileInput"
            style="display: none"
            @change="handleVideoFileUpload($event.target.files[0])"
          />

          <el-button
            v-if="!isView && data.properties.filed_content !== 'Hide'" type="primary"
            class="upload-file upload-button"
            @click="enableDialog"
            :loading="loading"
          >
            Upload
          </el-button>
          <el-button
            v-if="
              !isView && data.properties.filed_content !== 'Hide' && iframeURL
            "
            style="background-color: ivory; max-width: 27px; margin-left: 5px"
            type="plain"
            size="mini"
            circle
            @click="deleteImage()"
          >
            <img width="12px" src="@/assets/Trash.svg" />
          </el-button>
        </el-col>
      </span>
    </el-row>

    <dialog-component
      :title="'Upload Video'"
      :visible="showDialogue"
      @before-close="closeDialog"
      :containerWidth="'500px'"
      :containerHeight="'260px'"
    >
      <div>
        <p><b>Would you like to upload a video file?</b></p>
        <p>Supported file format: <strong>.MP4</strong></p>
        <p>File size: <strong>10MB</strong></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="mini">NO </el-button>
        <el-button @click="openFileUploader" size="mini" type="primary"
          >YES
        </el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EmbedYoutubeIframeHelper from "@/mixins/EmbedYoutubeIframeHelper";
import { mapGetters } from "vuex";
import appConfig from "@/config/app";

// import YouTubePlayer from "../../video/YouTubePlayer.vue";
// import VuePlayer from "../../vue-player/mixins/player";
// YouTubePlayer

export default {
  name: "templates-formComponentsExecute-VideoExecute",
  mixins: [userPermissionsHelper, EmbedYoutubeIframeHelper],
  components: {},
  props: ["data", "label", "form", "isView", "hasLabel", "highlight"],
  computed: {
    ...mapGetters("s3FileUpload", [
      "getFileUploadVideo",
      "getFileUploadStatus",
      "getFileUploadData",
    ]),
    getYoutubeVideoSource(value) {
      return this.getYoutubeIframe(value);
    },
  },
  mounted() {
    if (this.form[this.data.key]) {
      this.iframeURL = this.getYoutubeIframe(this.form[this.data.key]);
    } else if (this.form[`${this.data.key}_video`]) {
      this.iframeURL = this.getYoutubeIframe(
        this.form[`${this.data.key}_video`]
      );
    } else if (this.data.video_source_url) {
      this.iframeURL = this.getYoutubeIframe(this.data.video_source_url);
    } else {
      this.iframeURL = "";
    }
  },
  data() {
    return {
      validations: [],
      iframeURL: "",
      isDefalutPos: true,
      showLabel: true,
      showDialogue: false,
      loading: false,
    };
  },
  methods: {
    enableDialog() {
      this.showDialogue = true;
    },
    closeDialog() {
      this.showDialogue = false;
    },
    openFileUploader() {
      this.$refs.fileInput.click();
      this.showDialogue = false;
    },
    // handleVideoFileUpload(file) {
    //   const maxSizeInBytes = 10 * 1024 * 1024;
    //   if (file.size > maxSizeInBytes) {
    //     this.$alert("File size exceeds the maximum allowed size");
    //     return;
    //   }
    //   this.iframeURL = URL.createObjectURL(file);
    //   if (file && file.type.startsWith('video/')) {
    //     this.uploadVideoFile(file);
    //   } else {
    //     this.$alert("Please upload a valid video file");
    //   }
    // },
    handleVideoFileUpload(file) {
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        this.$alert("File size exceeds the maximum allowed size");
        return;
      }

      if (file.type === "video/mp4") {
        this.iframeURL = URL.createObjectURL(file);
        this.uploadVideoFile(file);
      } else {
        this.$alert("Please upload a valid video file (mp4 format).");
      }
    },
    async uploadVideoFile(file) {
      this.loading = true;

      try {
        if (file.type === "video/mp4") {
          var formData = new FormData();
          formData.append("video", file);

          await this.$store.dispatch("s3FileUpload/uploadVideoToS3", formData);
          if (this.getFileUploadStatus && this.getFileUploadData) {
            const videoUrl = `${appConfig.S3_BUCKET_URL}/${this.getFileUploadData}`;

            this.$set(this.form, this.data.key, videoUrl);
            this.$message.success("File uploaded successfully");
            this.loading = false;
          } else {
            this.$message.error("Error while uploading file");
          }
        }
      } catch (error) {
        console.error("Error during file upload:", error);
        this.$message.error("Error while uploading file");
      } finally {
        // Set loading to false regardless of success or failure to indicate the end of the operation
      }
    },

    checkReadonly() {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        // if(this.data.styles.labelStyle == 'right'){
        //   this.isDefalutPos = false;
        // }
      }
      // If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
    printvalue() {
      console.log("THIS : ", this.data);
    },
    getStyle() {
      return (
        `height:${this.data.height}px;width:${this.data.width}px;overflow:auto;` +
        this.getElementStyle
      );
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    deleteImage() {
      this.$set(this.form, this.data.key, null);
      this.iframeURL = "";
    },
  },
};
</script>

<style lang="scss">
.highlight-border {
  color: red;
}
.upload-button {
  padding: 9px 15px !important;
  font-size: 12px;
  border: none !important;
  border-radius: 5px !important;
}
</style>
